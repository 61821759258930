import { Link } from "react-router-dom";
import logoBranca from "../images/logo_branca.png";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footercolumns">
        <div className="firstcolumn">
          <ul>
            <Link to={"/minhaconta"}>
              <li>
                <button>Minha Conta</button>
              </li>
            </Link>
            <Link to={"/download"}>
              <li>
                <button>Download</button>
              </li>
            </Link>
            <Link to={"/minhaconta/pagamentos"}>
              <li>
                <button>Minha Assinatura</button>
              </li>
            </Link>
            <Link to={"/minhaconta/seguranca"}>
              <li>
                <button>Segurança da conta</button>
              </li>
            </Link>
            <Link to={"/newsletter"}>
              <li>
                <button>Newsletter</button>
              </li>
            </Link>
          </ul>
        </div>
        <div className="secondcolumn">
          <ul>
            <Link to={"/quemsomos"}>
              <li>
                <button>Quem Somos</button>
              </li>
            </Link>
            <Link to={"/ajuda"}>
              <li>
                <button>Suporte ao Cliente</button>
              </li>
            </Link>
            <Link to={"/ajuda"}>
              <li>
                <button>Perguntas Frequentes (FAQ)</button>
              </li>
            </Link>
            <Link to={"/termosdeservico"}>
              <li>
                <button>Termos De Serviço</button>
              </li>
            </Link>
            <Link to={"/politicadeprivacidade"}>
              <li>
                <button>Política de Privacidade</button>
              </li>
            </Link>
            <Link to={"/trabalheconosco"}>
              <li>
                <button>Trabalhe Conosco</button>
              </li>
            </Link>
          </ul>
        </div>
        <div className="thirdcolumn">
          <Link to={"/ajuda/ticket"}>
            <h1>contato@shishastream.com</h1>
          </Link>
          <Link to={"/ajuda"}>
            <div>
              <p>Horário de Atendimento:</p>
              <p>Segunda a Sexta: Das 8h às 20h.</p>
              <p>Sabado e Feriados: Das 10h às 16h.</p>
            </div>
          </Link>
        </div>
      </div>
      <div className="bottomfooter">
        <div className="logo">
          <img src={logoBranca} alt="Shisha Stream" />
          <p>Shisha Stream</p>
        </div>
        <div className="copyright">
          <p>© Copyright 2023 Shisha Stream. Todos os direitos reservados.</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
