import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import AccountNavList from "../../components/accountnavlist";
import { Link } from "react-router-dom";
import React from "react";
import FirebaseManager from "../../firebase/FirebaseManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from "react-router-dom";
import LoadingPage from "../../components/loading";
import FaleConoscoWidget from "../../components/FaleConoscoWidget";

const Notificacoes = () => {
  const [user, loading, error] = useAuthState(FirebaseManager.getAuth());

  if (loading) return <LoadingPage />;
  if (error) return <Navigate to="error" />;
  if (!user) return <Navigate to="/" />;
  return (
    <React.Fragment>
      <div className="pagesuserheader">
        <h2>Notificações</h2>
        <p>
          Gerencie suas preferências de notificações nos serviços Shisha Stream
        </p>
      </div>
      <div className="pagesuserwidgetscontainer">
        <div className="pagesuserwidget">
          <div className="pagesuserwidgetcontent">
            <h3>Notificações</h3>
            <button className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper">
                <div className="pagesusermain">
                  <p className="pagesuserbuttonlabel">Email</p>
                  <p className="pagesuserbuttontext">{user.email}</p>
                </div>
                <div className="pagesuserbuttonicon">
                  <i
                    className="bx bx-chevron-right bx-flip-vertical"
                    style={{ color: "hsla(0, 0%, 57%, 1)" }}
                  ></i>
                </div>
              </div>
            </button>
          </div>
        </div>
        <FaleConoscoWidget />
      </div>
    </React.Fragment>
  );
};

export default Notificacoes;
