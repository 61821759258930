import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import AccountNavList from "../../components/accountnavlist";
import { BrowserRouter as Router } from "react-router-dom";
import logo from "../../images/profileicon.png";
import { useEffect, useState, useRef, useContext } from "react";
import FirebaseManager from "../../firebase/FirebaseManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Navigate, useLocation, Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import Account from "./Account";
import SubscriptionTime from "../../firebase/SubscriptionTime";
import React from "react";
import LoadingPage from "../../components/loading";
import useMembership from "../../stripe/useMembershipStatus";
import UserInfoContext from "../../contexts/UserInfoContext";

const MinhaConta = () => {
  const location = useLocation();
  const [userPhoto, setPhoto] = useState(logo);
  const [user, loading, error] = useAuthState(FirebaseManager.getAuth());
  const navigate = useNavigate();
  const currentRoute = location.pathname;

  const { isMember,userRole, daysLeft, endDate,} = useContext(UserInfoContext)

  useEffect(() => {
    if (user) {
      if (user.photoURL) {
        setPhoto(user.photoURL);
        return;
      }

      setPhoto(logo);
    }

    return () => {
      setPhoto(logo);
    };
  }, [user]);

  if (loading) {
    return <LoadingPage />;
  } else if (user) {
    return (
      <div className="pages">
        <section className="minhacontapage">
          <AccountNavList />
          {currentRoute === "/minhaconta" ? (
            <Account user={user} userPhoto={userPhoto} subscriptionTime={daysLeft} subscriptionTimeEndDate={endDate} isMember={isMember} userRole={userRole} />
          ) : (
            <Outlet />
          )}
        </section>
        <Footer />
      </div>
    );
  } else if (error) {
    return (
      <Navigate to='/error?error="Não foi possivel autenticar sua conta"' />
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default MinhaConta;
