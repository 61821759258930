import React from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import ErrorPage from "../routes/ErrorPage";
import Home from "../routes/Home";
import Download from "../routes/Download";
import Diferenciais from "../routes/Diferenciais";
import QuemSomos from "../routes/QuemSomos";
import Planos from "../routes/Planos";
import Ajuda from "../routes/Ajuda";
import LogInPage from "../firebase/LoginPage";
import SingUpPage from "../firebase/SignUpPage";
import RedirectToHome from "../routes/RedirectToHome";
import MinhaConta from "../routes/minhaconta/MinhaConta";
import InformacoesPessoais from "../routes/minhaconta/InformacoesPessoais";
import Seguranca from "../routes/minhaconta/Seguranca";
import Notificacoes from "../routes/minhaconta/Notificacoes";
import Pagamentos from "../routes/minhaconta/Pagamentos";
import Termos from "../routes/Termos";
import PoliticaPrivacidade from "../routes/PoliticaPrivacidade";
import TrabalheConosco from "../routes/TrabalheConosco";
import Action from "../firebase/Action";
import LoadingPage from "./loading";
import MinhaAssinatura from "../routes/Assinatura";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<RedirectToHome />} />
        <Route path="/home" element={<Home />} />
        <Route path="/download" element={<Download />} />
        <Route path="/diferenciais" element={<Diferenciais />} />
        <Route path="/planos" element={<Planos />} />
        <Route path="/quemsomos" element={<QuemSomos />} />
        <Route path="/ajuda" element={<Ajuda />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/register" element={<SingUpPage />} />
        <Route path="/minhaconta" element={<MinhaConta />}>
          <Route path="informacoespessoais" element={<InformacoesPessoais />} />
          <Route path="seguranca" element={<Seguranca />} />
          <Route path="menunotificacao" element={<Notificacoes />} />
          <Route path="assinatura" element={<Pagamentos />} />
        </Route>
        <Route path="/minhaassinatura" element={<MinhaAssinatura />} />
        <Route path="/termosdeservico" element={<Termos />} />
        <Route
          path="/politicadeprivacidade"
          element={<PoliticaPrivacidade />}
        />
        <Route path="/trabalheconosco" element={<TrabalheConosco />} />
        <Route path="/action" element={<Action />} />
        <Route path="/wait" element={<LoadingPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
