import { getIdToken, getIdTokenResult } from "firebase/auth";
import FirebaseManager from "../firebase/FirebaseManager";

export default async function getUserMembership() {
  const user = FirebaseManager.getAuth().currentUser;
  let isMember = false;
  console.log(user);
  if (!user) {
    return { isMember };
  }

  await getIdToken(user, true);
  const decodedToken = await getIdTokenResult(user);
  if (
    !decodedToken ||
    !decodedToken.claims ||
    !decodedToken.claims.stripeRole
  ) {
    let isMember = false;
    return { isMember };
  }
  console.log(decodedToken);
  console.log(decodedToken.claims.stripeRole);
  isMember = true;
  let userRole = decodedToken.claims.stripeRole;
  return { isMember, userRole };
}
