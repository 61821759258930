
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import AccountNavList from "../../components/accountnavlist";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import FirebaseManager from "../../firebase/FirebaseManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from "react-router-dom";
import LoadingPage from "../../components/loading";
import FaleConoscoWidget from "../../components/FaleConoscoWidget";
import { useContext } from "react";
import {
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
} from "firebase/auth";
import UserInfoContext from "../../contexts/UserInfoContext";


const Seguranca = () => {
  const [user, loading, error] = useAuthState(FirebaseManager.getAuth());
  const { isMember, userRole, daysLeft, endDate, userProvider } = useContext(UserInfoContext)
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisable] = useState(false);

  if (loading) return <LoadingPage />;
  if (error) return <Navigate to="error" />;
  if (!user) return <Navigate to="/" />;

  const resetPassword = async (e) => {
    e.preventDefault();
    setDisable(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const credentials = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      if(newPassword === null || newPassword === "") {
        setErrorMessage("The password cannot be empty")
        setDisable(false)
        return;
      }

      if(repeatNewPassword === null || repeatNewPassword === "") {
        setErrorMessage("The Repeat password cannot be empty")
        setDisable(false)
        return;
      }


      if(newPassword !== repeatNewPassword) {
        setErrorMessage("The password doest not match")
        setDisable(false)
        return;
      }

      await reauthenticateWithCredential(user, credentials);
      await updatePassword(user, newPassword);
      setErrorMessage("Sua senha foi redefinida.");
      setCurrentPassword("");
      setNewPassword("");
      setErrorMessage();
      setDisable(false);
    } catch (error) {
      setErrorMessage(FirebaseManager.handleError(error));
      setDisable(false);
    }
  };

  return (
    <React.Fragment>
      <div className="pagesuserheader">
        <h2>Segurança</h2>
        <p>Configurações de segurança no serviço Shisha Stream</p>
      </div>
      {userProvider != "google.com" && <div className="pagesuserwidgetscontainer">
        <div className="pagesuserwidget" id="segurancapage">
          <div className="pagesuserwidgetcontent">
            <h3>Senha</h3>
            <button className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper">
                <div className="pagesusermain">
                  <p className="pagesuserbuttonlabel">Senha atual</p>
                  <input
                    disabled={disabled}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    type="password"
                    className="pagesuserbuttontext"
                    id="segurancapage"
                  />
                </div>
              </div>
            </button>
            <button className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper">
                <div className="pagesusermain">
                  <p className="pagesuserbuttonlabel">Nova senha</p>
                  <input
                    disabled={disabled}
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="password"
                    className="pagesuserbuttontext"
                    id="segurancapage"
                  />
                </div>
              </div>
            </button>
            <button className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper">
                <div className="pagesusermain">
                  <p className="pagesuserbuttonlabel">Confirme a nova senha</p>
                  <input
                    disabled={disabled}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    type="password"
                    className="pagesuserbuttontext"
                    id="segurancapage"
                  />
                </div>
              </div>
            </button>
            <div className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper" id="submitpasswordbutton">
                <button onClick={resetPassword}>Alterar senha</button>
                {errorMessage}
              </div>
            </div>
          </div>
          </div>
        </div>}
      <FaleConoscoWidget/>
      </React.Fragment>
  );
};

      export default Seguranca;
