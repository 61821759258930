import Footer from "../components/footer";
import { motion } from "framer-motion";
import FirebaseManager from "../firebase/FirebaseManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
const Planos = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(FirebaseManager.getAuth());
  return (
    <div className="pages">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: "easeInOut",
          },
        }}
        exit={{ opacity: 0 }}
      >
        <section className="mainpage" id="planos">
          <div className="cards">
            <button
              onClick={() => {
                if (user) {
                  navigate("/wait");
                  FirebaseManager.createCheckoutSession(user.uid, "trial");
                  return;
                }
                navigate("/login");
              }}
              className="card"
              id="betatestcard"
            >
              <div className="textcontainer">
                <p>
                  O Shisha Stream está passando por sua{" "}
                  <b>fase final de testes.</b>
                  <br />
                  Por conta disso, essa assinatura está disponível para você
                  usar a plataforma com um investimento pequeno.
                </p>
              </div>
              <ul>
                <li>
                  <b>Disponível até o fim da fase de testes.</b>
                </li>
                <li>
                  50% de desconto na sua assinatura no
                  <br />
                  1º mês <b>após a fase de testes</b>.
                </li>
                <li>Até 8 funcionários.</li>
                <li>Até 3 pontos de venda.</li>
                <li>Relatórios de performance em tempo real.</li>
              </ul>
            </button>
            <button
              onClick={async () => {
                const subscription =
                  await FirebaseManager.getSubscriptionInfo();
                console.log("b");
                console.log(subscription);
                axios.post("http://179.154.221.149:5000/changeSubscription", {
                  subscriptionId: subscription.id,
                  newProductId: "price_1NQmwlJvn0359VYGDDwKTJo7",
                });
              }}
              className="card"
              id="card1"
            >
              <div className="textcontainer">
                <p>
                  Mais <b>acessível</b> para pequenos negócios acelerarem seu
                  crescimento.
                </p>
              </div>
              <ul>
                <li>Até 5 funcionários.</li>
                <li>1 ponto de venda.</li>
                <li>Relatórios de performance semanais.</li>
              </ul>
            </button>
            <button className="card" id="card2">
              <div className="textcontainer">
                <p>
                  Mantenha-se conectado a múltiplos pontos de venda à todo
                  momento
                </p>
              </div>
              <ul>
                <li>Até 8 funcionários.</li>
                <li>Até 3 pontos de venda.</li>
                <li>Relatórios de performance em tempo real.</li>
              </ul>
            </button>
          </div>
        </section>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Planos;
