import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { getValue } from "@testing-library/user-event/dist/utils";

const AjudaCollapsible = () => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const contentVariants = {
    open: { height: "auto", opacity: 1 },
    collapsed: { height: 0, opacity: 0 },
  };

  return (
    <button className="faqdiv" onClick={toggleOpen}>
      <h2 className="faqbutton">FAQ</h2>
      <motion.div
        initial={open ? "open" : "collapsed"}
        animate={open ? "open" : "collapsed"}
        variants={contentVariants}
        transition={{ duration: 0.5, ease: "easeOut" }}
        style={{ overflow: "hidden" }}
      >
        <ul>
          <li>
            <h2>P: O que é o Shisha Stream?</h2>
            <p>
              R: A Shisha Stream é um software de gerenciamento para tabacarias
              e bares.
            </p>
          </li>
          <li>
            <h2>P: Como o Shisha Stream beneficia meu lounge de narguilé?</h2>
            <p>
              R: O Shisha Stream simplifica as operações diárias e economiza
              tempo na sua tabacaria.
            </p>
          </li>
          <li>
            <h2>P: Posso controlar meu estoque usando o Shisha Stream?</h2>
            <p>
              R: Sim, o Shisha Stream inclui um controle de estoque inteligente
              para acompanhar sabores, carvão e outros itens.
            </p>
          </li>
          <li>
            <h2>P: O Shisha Stream fornece análises e relatórios?</h2>
            <p>
              R: Sim, o Shisha Stream oferece análises e relatórios para obter
              insights sobre preferências dos clientes e desempenho do negócio.
            </p>
          </li>
          <li>
            <h2>P: O Shisha Stream fornece análises e relatórios?</h2>
            <p>
              R: Sim, o Shisha Stream oferece análises e relatórios para obter
              insights sobre preferências dos clientes e desempenho do negócio.
            </p>
          </li>
          <li>
            <h2>P: Como posso começar a usar o Shisha Stream?</h2>
            <p>
              R: Basta visitar nosso site e se inscrever para um teste gratuito
              para começar a usar o Shisha Stream.
            </p>
          </li>
        </ul>
      </motion.div>
    </button>
  );
};

export default AjudaCollapsible;
