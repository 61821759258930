import Footer from "../components/footer";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { motion } from "framer-motion";

const Termos = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const handleClick = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="pages">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: "easeInOut",
          },
        }}
        exit={{ opacity: 0 }}
      >
        <section className="termospage1">
          <h1>Termos de Serviço</h1>
          <div className="termospagecontainer">
            <div>
              <h2>1. Termos</h2>
              <p>
                Ao acessar o site <Link to={"/home"}>Shisha Stream</Link> e/ou o{" "}
                <Link to={"/download"}>aplicativo</Link>, concorda em cumprir
                estes termos de serviço, todas as leis e regulamentos aplicáveis
                ​​e concorda que é responsável pelo cumprimento de todas as leis
                locais aplicáveis. Se você não concordar com algum desses
                termos, está proibido de usar ou acessar este site. Os materiais
                contidos neste site são protegidos pelas leis de direitos
                autorais e marcas comerciais aplicáveis.
              </p>
            </div>
            <div>
              <h2>2. Uso de Licença</h2>
              <p>
                É concedida permissão para baixar temporariamente uma cópia dos
                materiais (informações ou software) no site Shisha Stream ,
                apenas para visualização transitória pessoal e não comercial.
                Esta é a concessão de uma licença, não uma transferência de
                título e, sob esta licença, você não pode:
              </p>
              <ul>
                <li>modificar ou copiar os materiais; </li>
                <li>
                  usar os materiais para qualquer finalidade comercial ou para
                  exibição pública (comercial ou não comercial);
                </li>
                <li>
                  tentar descompilar ou fazer engenharia reversa de qualquer
                  software contido no site Shisha Stream;
                </li>
                <li>
                  remover quaisquer direitos autorais ou outras notações de
                  propriedade dos materiais; ou
                </li>
                <li>
                  transferir os materiais para outra pessoa ou 'espelhe' os
                  materiais em qualquer outro servidor.
                </li>
              </ul>
              <p>
                Esta licença será automaticamente rescindida se você violar
                alguma dessas restrições e poderá ser rescindida por Shisha
                Stream a qualquer momento. Ao encerrar a visualização desses
                materiais ou após o término desta licença, você deve apagar
                todos os materiais baixados em sua posse, seja em formato
                eletrónico ou impresso.
              </p>
            </div>
            <div>
              <h2>3. Limitações</h2>
              <p>
                Em nenhum caso o Shisha Stream ou seus fornecedores serão
                responsáveis ​​por quaisquer danos (incluindo, sem limitação,
                danos por perda de dados ou lucro ou devido a interrupção dos
                negócios) decorrentes do uso ou da incapacidade de usar os
                materiais em Shisha Stream, mesmo que Shisha Stream ou um
                representante autorizado da Shisha Stream tenha sido notificado
                oralmente ou por escrito da possibilidade de tais danos. Como
                algumas jurisdições não permitem limitações em garantias
                implícitas, ou limitações de responsabilidade por danos
                consequentes ou incidentais, essas limitações podem não se
                aplicar a você.
              </p>
            </div>
          </div>
          <button onClick={() => handleClick(ref1)} className="scrolldown">
            <i
              id="guideicon"
              style={{ color: "#808191", fontSize: "24px" }}
              className="bx bx-chevron-down"
            />
          </button>
        </section>
        <section ref={ref1} className="termospage2">
          <div className="termospagecontainer" id="termos2">
            <div>
              <h2>4. Insenção de responsabilidade</h2>
              <ul>
                <li>
                  Os materiais no site da Shisha Stream são fornecidos 'como
                  estão'. Shisha Stream não oferece garantias, expressas ou
                  implícitas, e, por este meio, isenta e nega todas as outras
                  garantias, incluindo, sem limitação, garantias implícitas ou
                  condições de comercialização, adequação a um fim específico ou
                  não violação de propriedade intelectual ou outra violação de
                  direitos.
                </li>
                <li>
                  Além disso, o Shisha Stream não garante ou faz qualquer
                  representação relativa à precisão, aos resultados prováveis
                  ​​ou à confiabilidade do uso dos materiais em seu site ou de
                  outra forma relacionado a esses materiais ou em sites
                  vinculados a este site.
                </li>
                <li>
                  O Shisha Stream não será responsável por reter ou manter os
                  dados do cliente após um período de 30 dias após o vencimento
                  ou não renovação de sua associação. É responsabilidade do
                  cliente garantir que todos os dados necessários sejam copiados
                  e salvos antes do vencimento de sua associação. O Shisha
                  Stream reserva-se o direito de excluir permanentemente ou
                  descartar quaisquer dados do cliente após o período de 30 dias
                  especificado, sem aviso prévio e/ou responsabilidade
                  adicional. Recomendamos enfaticamente que os clientes façam os
                  arranjos necessários para backup ou recuperação de dados antes
                  do vencimento ou não renovação de sua associação, a fim de
                  evitar qualquer possível perda de dados.
                </li>
              </ul>
            </div>
            <div>
              <h2>5. Precisão dos materiais</h2>
              <p>
                Os materiais exibidos no site da Shisha Stream podem incluir
                erros técnicos, tipográficos ou fotográficos. Shisha Stream não
                garante que qualquer material em seu site seja preciso, completo
                ou atual. Shisha Stream pode fazer alterações nos materiais
                contidos em seu site a qualquer momento, sem aviso prévio. No
                entanto, Shisha Stream não se compromete a atualizar os
                materiais.
              </p>
            </div>
            <div>
              <h2>6. Links</h2>
              <p>
                O Shisha Stream não analisou todos os sites vinculados ao seu
                site e não é responsável pelo conteúdo de nenhum site vinculado.
                A inclusão de qualquer link não implica endosso por Shisha
                Stream do site. O uso de qualquer site vinculado é por conta e
                risco do usuário.
              </p>
            </div>
          </div>
          <button
            onClick={() => handleClick(ref2)}
            className="scrolldown"
            id="termos2"
          >
            <i
              id="guideicon"
              style={{ color: "#808191", fontSize: "24px" }}
              className="bx bx-chevron-down"
            />
          </button>
        </section>
        <section ref={ref2} className="termospage3">
          <div className="termospagecontainer" id="termos3">
            <div>
              <h4>Modificações</h4>
              <p>
                O Shisha Stream pode revisar estes termos de serviço do site a
                qualquer momento, sem aviso prévio. Ao usar este site, você
                concorda em ficar vinculado à versão atual desses termos de
                serviço.
              </p>
            </div>
            <div>
              <h4>Lei aplicável</h4>
              <p>
                Estes termos e condições são regidos e interpretados de acordo
                com as leis do Shisha Stream e você se submete irrevogavelmente
                à jurisdição exclusiva dos tribunais naquele estado ou
                localidade.
              </p>
            </div>
            <h3>Shisha Stream</h3>
          </div>
        </section>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Termos;
