import { useState, useEffect } from "react";
import getUserMembership from "./isUserMember";

export default function useMembership(user) {
  const [info, setInfo] = useState({});
  useEffect(() => {
    const checkMembership = async () => {
      const membershipInfo = await getUserMembership();
      console.log(membershipInfo);
      setInfo(membershipInfo);
    };
    checkMembership();
  }, [user]);

  return info;
}
