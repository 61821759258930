import { motion } from "framer-motion";

const LoadingPage = () => {
  const randomNumber = Math.floor(Math.random() * 20);
  const isSpecialAnimation = randomNumber === 0;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="loadingBackground"
    >
      <h1 style={{ color: "black", fontWeight: "400" }}>
        Carregando o Shisha Stream...
      </h1>
      <div className={isSpecialAnimation ? "dot-revolution" : "dot-flashing"} />
    </motion.div>
  );
};

export default LoadingPage;
