import Footer from "../components/footer";
import { motion } from "framer-motion";

const Diferenciais = () => {
  return (
    <div className="pages">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: "easeInOut",
          },
        }}
        exit={{ opacity: 0 }}
      >
        <section className="diferenciaispage">
          <h1>Por que escolher o Shisha Stream?</h1>
          <div className="diferenciaispagecontainer">
            <ul>
              <li>
                <b>Gestão simplificada e análise de tendências de compra:</b>{" "}
                Tenha controle total com uma interface intuitiva para gerenciar
                estoque, vendas, relacionamento com clientes.
              </li>
              <li>
                <b>Controle de estoque eficiente:</b> Acompanhe em tempo real as
                movimentações de produtos e evite problemas de falta ou excesso.
              </li>
              <li>
                <b>Ferramentas poderosas de vendas:</b> Analise o comportamento
                de compra dos clientes, crie promoções personalizadas e ofereça
                descontos exclusivos.
              </li>
              <li>
                <b>Análises e relatórios completos:</b> Tome decisões embasadas
                em dados reais sobre vendas, estoque, preferências dos clientes
                e desempenho dos funcionários.
              </li>
              <li>
                <b>
                  Monitoramento do rendimento e atividades dos funcionários:
                </b>{" "}
                A interface do Shisha Stream permite a inclusão de múltiplos
                usuários, permitindo que você monitore o rendimento e as
                atividades de cada funcionário. Mantenha-se informado sobre o
                desempenho individual e identifique oportunidades de melhoria.
              </li>
              <li>
                <b>Suporte dedicado:</b> Conte com nossa equipe para solucionar
                dúvidas e fornecer orientações para o sucesso da sua tabacaria.
              </li>
            </ul>
          </div>
        </section>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Diferenciais;
