import Footer from "../components/footer";
import { motion } from "framer-motion";

const QuemSomos = () => {
  return (
    <div className="pages">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: "easeInOut",
          },
        }}
        exit={{ opacity: 0 }}
      >
        <section className="quemsomospage">
          <h1>Institucional</h1>
          <div className="quemsomospagecontainer">
            <div className="quemsomos">
              <h2>Quem Somos</h2>
              <p>
                O Shisha Stream é uma ferramenta comprometida em simplificar a
                gestão de tabacarias de forma inteligente. Nossa plataforma
                oferece controle completo sobre estoque, vendas e relacionamento
                com clientes.
              </p>
              <p>
                Acreditamos que a gestão de uma tabacaria não precisa ser
                complicada. Com o Shisha Stream, proporcionamos uma interface
                intuitiva e inteligente, projetada para oferecer controle
                completo sobre todos os aspectos do seu negócio. Desde o
                gerenciamento de estoque até as vendas e relacionamento com os
                clientes, nossa plataforma simplifica e agiliza as tarefas
                diárias, permitindo que você se concentre no crescimento e no
                sucesso do seu negócio.
              </p>
              <p>
                Nosso compromisso é impulsionar o sucesso das tabacarias e
                lounges, oferecendo uma plataforma única, eficiente e orientada
                para resultados. Junte-se a nós hoje mesmo e transforme sua
                gestão, elevando sua tabacaria a um novo patamar.
              </p>
            </div>
            <div className="nossahistoria">
              <h2>Nossa História</h2>
              <p>
                O Shisha Stream começou a ser desenvolvido em 2022 com o
                conceito de uma plataforma inteligente, capaz de processar e
                interpretar os dados de vendas de uma tabacaria, de forma que
                fosse possível estipular o crescimento de um negócio e otimizar
                o controle de estoque.
              </p>
              <p>
                Com isso, nasceu o sonho de ajudar tabacarias a acelerar seu
                crescimento, otimizando suas vendas e removendo a necessidade de
                um supervisor no PDV para controlar as vendas.
              </p>
              <p>
                Após alguns meses de tentativa e erro, aperfeiçoando a
                plataforma, o Shisha Stream ficou pronto para uso profissional,
                entrando na sua fase de testes pública, onde se encontra
                atualmente.
              </p>
              <p>
                No ano de 2023, a plataforma foi lançada, sendo considerada
                oficialmente a primeira plataforma voltada para tabacarias do
                mercado com análise inteligente de dados.
              </p>
            </div>
          </div>
        </section>
      </motion.div>
      <Footer />
    </div>
  );
};

export default QuemSomos;
