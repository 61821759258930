import Footer from "../components/footer";
import { motion } from "framer-motion";

const TrabalheConosco = () => {
  return (
    <div className="pages">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: "easeInOut",
          },
        }}
        exit={{ opacity: 0 }}
      >
        <section className="trabalhepage">
          <p>Não está disponível no momento</p>
        </section>
      </motion.div>
      <Footer />
    </div>
  );
};

export default TrabalheConosco;
