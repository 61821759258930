import ErrorPopup from "./errorpopup";

const PopupManager = ({ errors, removeFunction }) => {
  return (
    <div className="errorWrapper">
      {errors.map((error) => {
        switch (error.type) {
          case "error":
            return (
              <ErrorPopup
                isVisible={true}
                key={error.id}
                message={error.message}
                onClose={() => removeFunction(error.id)}
              />
            );

          default:
            break;
        }
      })}
    </div>
  );
};

export default PopupManager;
