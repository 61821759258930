import logoPreta from "../images/logo_preta.png";
import { Link, useLocation } from "react-router-dom";
import FirebaseManager from "../firebase/FirebaseManager";
import { useAuthState, useUpdateProfile } from "react-firebase-hooks/auth";
import logo from "../images/profileicon.png";
import { useEffect, useState, useRef } from "react";
import useMembershipStatus from "../stripe/useMembershipStatus";
import { motion, AnimatePresence } from "framer-motion";

const Navbar = (activeTab) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const [user, loading, error] = useAuthState(FirebaseManager.getAuth());
  var [userPhoto, setPhoto] = useState(logo);
  const [isaccountDropdownOpen, setaccountDropdown] = useState(false);
  const [isnotificationDropdownOpen, setnotificationDropdown] = useState(false);

  const accountHandle = useRef(null);
  const accountDropdown = useRef(null);
  const notificationHandle = useRef(null);
  const notificationDropdown = useRef(null);

  const { isMember } = useMembershipStatus(user);

  const handleClickOutside = (event) => {
    if (
      accountHandle.current &&
      accountDropdown.current &&
      !accountHandle.current.contains(event.target) &&
      !accountDropdown.current.contains(event.target)
    ) {
      setaccountDropdown(isaccountDropdownOpen);
    }

    if (
      notificationHandle.current &&
      notificationDropdown.current &&
      !notificationHandle.current.contains(event.target) &&
      !notificationDropdown.current.contains(event.target)
    ) {
      setnotificationDropdown(isnotificationDropdownOpen);
    }
  };

  useEffect(() => {
    console.log(user);
    if (user) {
      if (user.photoURL) {
        setPhoto(user.photoURL);
        return;
      }
      setPhoto(logo);
    }
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = document.body.scrollTop;
    setScrollPosition(position);
  };

  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll);
    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log("User Effect");
    console.log(user);
    if (user) {
      if (user.photoURL) {
        setPhoto(user.photoURL);
        return;
      }
      setPhoto(logo);
    }
  }, [user]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/action"
  ) {
    return null;
  }

  function returnActiveStyle(route) {
    return currentRoute === route ? { opacity: 1 } : null;
  }

  const boxShadowVariants = {
    visible: {
      boxShadow: "-8px 2px 8px 0px rgba(0, 0, 0, 0.1)",
      transition: {
        duration: 0.3,
      },
    },
    hidden: {
      boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0)",
      transition: {
        duration: 0.25,
      },
    },
  };

  return (
    <div className="dropdownPlaceholder">
      <AnimatePresence>
        <motion.div
          initial={false}
          animate={scrollPosition > 20 ? "visible" : "hidden"}
          exit="hidden"
          variants={boxShadowVariants}
          className={"navbar"}
        >
          <button className="navbar-left">
            <Link className="logo" to="/home">
              <img src={logoPreta} alt="Shisha Stream" />
              <div className="logo" id="logotext">
                Shisha Stream
              </div>
            </Link>
          </button>
          <div className="navbar-center">
            {user && !loading ? (
              <div className="navbar-center">
                <Link style={returnActiveStyle("/download")} to="/download">
                  <button>Download</button>
                </Link>
                {isMember ? (
                  <Link
                    style={returnActiveStyle("/minhaassinatura")}
                    to="/minhaassinatura"
                  >
                    <button>Minha Assinatura</button>
                  </Link>
                ) : (
                  <Link
                    style={returnActiveStyle("/diferenciais")}
                    to="/diferenciais"
                  >
                    <button>Diferenciais</button>
                  </Link>
                )}
                <Link style={returnActiveStyle("/planos")} to="/planos">
                  <button>Planos</button>
                </Link>
                <Link style={returnActiveStyle("/ajuda")} to="/ajuda">
                  <button>Ajuda</button>
                </Link>
              </div>
            ) : (
              <div className="navbar-center">
                <Link style={returnActiveStyle("/download")} to="/download">
                  <button>Download</button>
                </Link>
                <Link
                  style={returnActiveStyle("/diferenciais")}
                  to="/diferenciais"
                >
                  <button>Diferenciais</button>
                </Link>
                <Link style={returnActiveStyle("/planos")} to="/planos">
                  <button>Planos</button>
                </Link>
                <Link style={returnActiveStyle("/quemsomos")} to="/quemsomos">
                  <button>Quem Somos</button>
                </Link>
                <Link style={returnActiveStyle("/ajuda")} to="/ajuda">
                  <button>Ajuda</button>
                </Link>
              </div>
            )}
            {user && !loading ? (
              /* Navbar User Info */ <div>
                <div
                  style={returnActiveStyle("/minhaconta")}
                  className="userhandle"
                >
                  <button
                    onClick={() => {
                      setnotificationDropdown(!isnotificationDropdownOpen);
                    }}
                    ref={notificationHandle}
                    className="roundIcon"
                    id="navbarnotificationbutton"
                  >
                    <i id="guideicon" className="bx bx-bell"></i>
                  </button>
                  <button
                    className="navbarprofilephoto"
                    style={isMember ? { backgroundColor: "#ffd70033" } : {}}
                  >
                    <img
                      onClick={() => {
                        setaccountDropdown(!isaccountDropdownOpen);
                      }}
                      ref={accountHandle}
                      style={returnActiveStyle("/minhaconta ")}
                      src={user.photoURL}
                      alt="perfil"
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div className="navbar-right">
                <div className="button">
                  <Link className="button" id="log-in" to="/login">
                    <button>Login</button>
                  </Link>
                  <Link className="button" id="sign-in" to="/register">
                    <button>Criar conta</button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
      {isnotificationDropdownOpen && user ? (
        <div
          ref={notificationDropdown}
          className="notificationDropdown"
          onClick={() => {
            setnotificationDropdown(!isnotificationDropdownOpen);
          }}
        >
          <div className="notificationview">
            <div className="notification">
              <div className="accountInfo"></div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {isaccountDropdownOpen && user ? (
        <div
          ref={accountDropdown}
          className="accountDropdown"
          onClick={() => {
            setaccountDropdown(!isaccountDropdownOpen);
          }}
        >
          <div className="accountview">
            <div className="account">
              <img src={user.photoURL} alt="perfil" />
              <div className="accountInfo">
                <h3>{user.displayName}</h3>
                <p>{user.email}</p>
              </div>
            </div>
            <button className="accountbutton">
              <Link to="/minhaconta" style={{ textDecoration: "none" }}>
                <p>Gerenciar minha conta</p>
              </Link>
            </button>
          </div>
          <button
            className="signoutbutton"
            onClick={async () => {
              try {
                await FirebaseManager.signOut();
                setaccountDropdown(false);
              } catch (error) {
                console.log(error);
              }
            }}
          >
            <i className="bx bx-log-out bx-rotate-180"></i>
            <p>Sair da conta</p>
          </button>
          <div className="politicaetermosbutton">
            <button className="politicabutton">
              <Link to={"/politicadeprivacidade"}>
                <p>Política de Privacidade</p>
              </Link>
            </button>
            <h5>•</h5>
            <button className="termosbutton">
              <Link to={"/termosdeservico"}>
                <p>Termos de Serviço</p>
              </Link>
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Navbar;
