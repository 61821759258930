import "./SignUp.css";

import { useState } from "react";
import firebaseManager, { createDatabaseAccount } from "./FirebaseManager";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import FormValidation from "./FormValidation";

const SingUpPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secondName, setSecondName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [ErrMessage, setErrMessage] = useState("");
  const [disabled, setDisable] = useState(false);

  const navigate = useNavigate();

  const submitForm = async (event) => {
    setDisable(true);
    setErrMessage(null);
    event.preventDefault();
    const name = firstName + " " + secondName;
    const { isValid, message } = FormValidation.validateRegisterInputs(
      email,
      password,
      firstName,
      secondName
    );

    if (!isValid) {
      setErrMessage(message);
      setDisable(false);
      return;
    }

    try {
      await firebaseManager.registerUser(email, password, name);
      navigate("/");
    } catch (error) {
      setDisable(false);
      console.log(error);
      setErrMessage(firebaseManager.handleError(error));
    }
  };

  return (
    <div className="signupBackground">
      <div className="signupForm">
        <h1>Faça seu registro.</h1>
        <form onSubmit={submitForm} className="inputFields">
          <div className="inputFlex">
            <div className="doubleInputLabels">
              <label className="signuplabel" htmlFor="displayName">
                Primeiro Nome
                <mark style={{ background: "none", color: "gold" }}> *</mark>
              </label>
              <label className="signuplabel" htmlFor="displayName">
                Segundo Nome
                <mark style={{ background: "none", color: "gold" }}> *</mark>
              </label>
            </div>
            <div className="sameLineInput">
              <input
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                disabled={disabled}
                maxLength={32}
                name="displayName"
                className="signupemail"
                style={{ paddingRight: "0" }}
                required=""
                aria-required="true"
              />
              <div className="sameLineDivider">|</div>
              <input
                onChange={(e) => setSecondName(e.target.value)}
                type="text"
                disabled={disabled}
                maxLength={32}
                style={{ paddingLeft: "5px" }}
                name="displayName"
                className="signupemail"
                required=""
                aria-required="true"
              />
            </div>
          </div>

          <div className="inputFlex">
            <label className="signuplabel" htmlFor="email">
              Email<mark style={{ background: "none", color: "gold" }}> *</mark>
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              disabled={disabled}
              className="signupemail"
              required=""
              aria-required="true"
            />
          </div>
          <div className="inputFlex">
            <label className="signuplabel" htmlFor="password">
              Password
              <mark style={{ background: "none", color: "gold" }}> *</mark>
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              maxLength={32}
              name="password"
              disabled={disabled}
              className="signupemail"
              required=""
              aria-required="true"
            />
          </div>
          <input
            disabled={disabled}
            type="submit"
            value="Registrar"
            className="signupemail"
          />
          <p style={{ color: "white", marginTop: "10px" }}>
            {ErrMessage ? ErrMessage : " "}
          </p>
        </form>

        <div className="signoptions">
          <div className="signoptionsButton">
            <div className="signoptionsContent">
              <i className="bx bxl-google" />
              <p>Google</p>
            </div>
          </div>

          <div onClick={() => navigate("/login")} className="signoptionsButton">
            <div className="signoptionsContent">
              <i className="bx bx-log-in" />
              <p>Log-in</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingUpPage;
