import { useAuthState } from "react-firebase-hooks/auth"
import UserInfoContext from "./UserInfoContext"
import FirebaseManager from "../firebase/FirebaseManager"
import { useEffect, useState } from "react"
import SubscriptionTime from "../firebase/SubscriptionTime"
import useMembership from "../stripe/useMembershipStatus"

const UserInfoProvider = ({ children }) => {
  const [user] = useAuthState(FirebaseManager.getAuth())
  const { isMember, userRole } = useMembership(user);

  const [daysLeft, setDaysLeft] = useState('NaN')
  const [endDate, setEndDate] = useState('NaN')
  const [startDate, setStartDate] = useState('NaN')
  const [provider, setProvider] = useState('NaN')
  const [info, setInfo] = useState({
    isMember: false,
    userRole: 'none',
    daysLeft: 'NaN',
    endDate: 'NaN',
    startDate: 'NaN',
    userProvider: 'NaN'
  })

  async function getTime() {
    try {
      const { cycleTimeTotalDays, cycleEnd, cycleStart } = await SubscriptionTime.getSubscriptionTime();
      console.log(cycleTimeTotalDays)
      setDaysLeft(Math.floor(cycleTimeTotalDays / (60 * 60 * 24)));
      setEndDate(SubscriptionTime.formatTime(cycleEnd.toDate()))
      setStartDate(SubscriptionTime.formatTime(cycleStart.toDate()))
    } catch (error) {
      console.log(error)
    }
  }

  async function getProvider() {
    try {
      const {provider} = await FirebaseManager.getUserInfo();
      setProvider(provider)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log("Provider Effect")
    getTime()
    getProvider()
  }, [user])

  useEffect(() => {
    setInfo({
        isMember: isMember,
        userRole: userRole,
        daysLeft: daysLeft,
        endDate: endDate,
        startDate: startDate,
        userProvider: provider
      })
  },[isMember,userRole,daysLeft,endDate,provider,startDate])

  return <UserInfoContext.Provider value={info}>{children}</UserInfoContext.Provider>
}

export default UserInfoProvider;
