import Footer from "../components/footer";
import AjudaCollapsible from "../components/ajudacollapsible";
import { motion } from "framer-motion";
import TicketForm from "../components/ticketform";

const Ajuda = () => {
  return (
    <div className="pages">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: "easeInOut",
          },
        }}
        exit={{ opacity: 0 }}
      >
        <section className="ajudapage">
          <div className="ajudacontainer">
            <div>
              <AjudaCollapsible />
            </div>
            <div className="ticket">
              <h1>Fale Conosco</h1>
              <TicketForm />
            </div>
          </div>
        </section>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Ajuda;
