export default {
  validateEmail: (email) => {
    const emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );

    const isEmailValid =
      emailPattern.test(email) && email !== null && email !== "";

    return isEmailValid;
  },

  validateSamePassword: (passwordOne, passwordTwo) => {
    return passwordOne === passwordTwo;
  },

  validatePassword: (password) => {
    const isPasswordValid =
      password.length > 7 && password !== null && password !== "";

    return isPasswordValid;
  },

  validateContactInput: (email, name, message) => {
    const emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );

    const isEmailValid =
      emailPattern.test(email) && email !== null && email !== "";

    const isMessageValid = message !== null && message !== "";

    const isNameValid = name !== null && name !== "";

    if (isEmailValid && isMessageValid && isNameValid) {
      return { isValid: true };
    } else {
      let message = "";
      if (!isEmailValid) {
        message += "O email é invalido";
      }
      if (!isMessageValid) {
        message += "Você deve escrever uma mensagem";
      }
      if (!isNameValid) {
        message += "Você deve informar um nome";
      }
      return { isValid: false, message };
    }
  },
  validateRegisterInputs: (email, password, firstName, SecondName) => {
    const emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );

    const isEmailValid =
      emailPattern.test(email) && email !== null && email !== "";

    const isPasswordValid =
      password.length > 7 && password !== null && password !== "";

    const isFirstNameValid =
      firstName.length > 0 && firstName !== null && firstName !== "";

    const isSecondNameValid =
      SecondName.length > 0 && SecondName !== null && SecondName !== "";

    if (
      isEmailValid &&
      isPasswordValid &&
      isFirstNameValid &&
      isSecondNameValid
    ) {
      return { isValid: true };
    } else {
      let message = "";
      if (!isEmailValid) {
        message += " Email is invalid.";
      }
      if (!isPasswordValid) {
        message += " Password should be at least 8 characters long.";
      }
      if (!isFirstNameValid) {
        message += "First Name should not be empty.";
      }

      if (!isSecondNameValid) {
        message += "Second Name should not be empty.";
      }

      return { isValid: false, message };
    }
  },

  validateLoginInputs: (email, password) => {
    const emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );

    const isEmailValid =
      emailPattern.test(email) && email !== null && email !== "";

    const isPasswordValid =
      password.length > 7 && password !== null && password !== "";

    if (isEmailValid && isPasswordValid) {
      return { isValid: true };
    } else {
      let message = [];
      if (!isEmailValid) {
        message.push("Email is invalid.");
      }
      if (!isPasswordValid) {
        message.push(" Password should be at least 8 characters long.");
      }
      return { isValid: false, message };
    }
  },
};
