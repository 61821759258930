import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import AccountNavList from "../../components/accountnavlist";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import React from "react";
import FirebaseManager from "../../firebase/FirebaseManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useNavigate } from "react-router-dom";
import LoadingPage from "../../components/loading";
import FaleConoscoWidget from "../../components/FaleConoscoWidget";

const Pagamentos = () => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(FirebaseManager.getAuth());

  if (loading) return <LoadingPage />;
  if (error) return <Navigate to="error" />;
  if (!user) return <Navigate to="/" />;

  return (
    <React.Fragment>
      <div className="pagesuserheader">
        <h2>Pagamentos e Assinatura</h2>
        <p>Gerencie seus métodos de pagamento e cancelamentos</p>
      </div>
      <div className="pagesuserwidgetscontainer">
        <button
          onClick={async (e) => {
            e.preventDefault();
            try {
              FirebaseManager.generatePortalLink();
              navigate("/wait");
            } catch (error) {
              console.log(error);
            }
          }}
          className="userwidget"
          id="pagamentoswidget"
        >
          <div className="userwidgetcontent">
            <h3>Métodos de pagamento</h3>
            <p>
              Aqui no Shisha Stream utilizamos a plataforma Stripe para
              pagamentos. Por conta disso, para fazer alterações nos métodos de
              pagamento e cancelamentos, te redirecionamos para o seu painel de
              controle no site deles.
            </p>
          </div>
          <div className="userwidgetbutton">
            <p>Gerenciar métotos de pagamento</p>
          </div>
        </button>
      </div>
      <FaleConoscoWidget />
    </React.Fragment>
  );
};

export default Pagamentos;
