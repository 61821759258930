import { useState } from "react";
import { useNavigate } from "react-router-dom";
import firebaseManager from "./FirebaseManager";
import FormValidation from "./FormValidation";

const ChangePassword = ({ actionCode }) => {
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [ErrMessage, setErrMessage] = useState("");
  const [disabled, setDisable] = useState(false);
  const navigate = useNavigate();

  console.log("actionCode ", actionCode);
  const submitForm = async (event) => {
    setDisable(true);
    event.preventDefault();
    const isValidFormat = FormValidation.validatePassword(password);
    const isSamePassword = FormValidation.validateSamePassword(
      password,
      passwordCheck
    );
    if (!isValidFormat) {
      setErrMessage(" Password should be at least 8 characters long.");
      setDisable(false);
      return;
    }

    if (!isSamePassword) {
      setErrMessage("The passwords doesn't match");
      setDisable(false);
      return;
    }

    try {
      await firebaseManager.handlePasswordChange(actionCode, password);
      navigate("/login");
    } catch (error) {
      setDisable(false);
      console.log(error);
      setErrMessage(firebaseManager.handleError(error.code));
    }
  };

  return (
    <div className="loginBackground">
      <div className="loginForm">
        <h1>Redefina a senha.</h1>
        <form onSubmit={submitForm} className="inputFields">
          <div className="inputFlex">
            <label className="loginlabel" htmlFor="password">
              Password
              <mark
                style={{ fontWeight: "800", background: "none", color: "gold" }}
              ></mark>
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              maxLength={32}
              disabled={disabled}
              name="password"
              className="loginemail"
              required=""
              aria-required="true"
            />
          </div>
          <div className="inputFlex">
            <label className="loginlabel" htmlFor="passwordCheck">
              Type your password again
              <mark
                style={{ fontWeight: "800", background: "none", color: "gold" }}
              ></mark>
            </label>
            <input
              onChange={(e) => setPasswordCheck(e.target.value)}
              type="password"
              maxLength={32}
              disabled={disabled}
              name="passwordCheck"
              className="loginemail"
              required=""
              aria-required="true"
            />
          </div>
          <input
            type="submit"
            disabled={disabled}
            value={"Redefinir"}
            className="loginemail"
          />
          <p style={{ color: "white", marginTop: "10px" }}>
            {ErrMessage ? ErrMessage : " "}
          </p>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
