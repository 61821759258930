import { useLocation, useNavigate, Navigate } from "react-router-dom";
import FirebaseManager from "./FirebaseManager";
import { useEffect } from "react";
import ChangePassword from "./ChangePassword";

const Action = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");

  console.log(actionCode);
  console.log(mode);
  useEffect(() => {
    if (!mode || mode == "" || actionCode == "" || !actionCode)
      navigate('/error?error="Link de ação inválido"');
  }, []);

  switch (mode) {
    case "resetPassword":
      return <ChangePassword actionCode={actionCode} />;
    case "recoverEmail":
      return <Navigate to="/error" />;
    case "verifyEmail":
      try {
        FirebaseManager.handleEmailVerification(actionCode);
        return <Navigate to="/minhaconta" />;
      } catch (error) {
        console.log(error);
        return (
          <Navigate to='/error?error="Não foi possivel verificar seu email"' />
        );
      }
    default:
  }
};

export default Action;
