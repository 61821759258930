import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import UserInfoContext from "../contexts/UserInfoContext";

const AccountNavList = () => {
  const location = useLocation();
  const currentRoute = location.pathname;

  function returnActiveStyle(route) {
    console.log(route ? "currentNavActive" : null);
    return currentRoute == route ? "currentNavActive" : null;
  }

  const { isMember, userRole, daysLeft, endDate, userProvider } =
    useContext(UserInfoContext);

  return (
    <div className="accountnavlist">
      <Link className={returnActiveStyle("/minhaconta")} to={"/minhaconta"}>
        <button className={"accountnavbutton"}>
          <i id="usericons" class="bx bx-user-circle"></i>
          <p>Início</p>
        </button>
      </Link>
      <Link
        to={"/minhaconta/informacoespessoais"}
        className={returnActiveStyle("/minhaconta/informacoespessoais")}
      >
        <button className="accountnavbutton">
          <i id="usericons" className="bx bx-id-card"></i>
          <p>Informações pessoais</p>
        </button>
      </Link>
      <Link
        to={"/minhaconta/menunotificacao"}
        className={returnActiveStyle("/minhaconta/menunotificacao")}
      >
        <button className="accountnavbutton">
          <i id="usericons" className="bx bx-bell"></i>
          <p>Notificações</p>
        </button>
      </Link>
      {userProvider === "password" && (
        <Link
          to={"/minhaconta/seguranca"}
          className={returnActiveStyle("/minhaconta/seguranca")}
        >
          <button className="accountnavbutton">
            <i id="usericons" className="bx bx-lock-alt"></i>
            <p>Segurança</p>
          </button>
        </Link>
      )}
      <Link
        className={returnActiveStyle("/minhaconta/assinatura")}
        to={"/minhaconta/assinatura"}
      >
        <button className="accountnavbutton">
          <i id="usericons" className="bx bx-credit-card"></i>
          <p>Pagamentos e assinatura</p>
        </button>
      </Link>
    </div>
  );
};

export default AccountNavList;
