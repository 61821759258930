import React from "react";
import ReactDOM from "react-dom/client";
import AnimatedRoutes from "./components/animatedroutes";

import { BrowserRouter, Routes } from "react-router-dom";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import UserInfoProvider from "./contexts/UserInfoProvider";

function Router() {
  return (
    <BrowserRouter>
    <UserInfoProvider>
      <Navbar />
      <AnimatedRoutes />
      </UserInfoProvider>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(Router());
