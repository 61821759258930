import React from "react";
import { useState } from "react";
import FormValidation from "../firebase/FormValidation";

const TicketForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [supportticket, setSupportTicket] = useState("");
  const [ErrMessage, setErrMessage] = useState("");
  const [disabled, setDisable] = useState(false);

  const submitForm = async (event) => {
    setDisable(true);
    setErrMessage(null);
    event.preventDefault();

    const { isValid, message } = FormValidation.validateContactInput(
      email,
      name,
      supportticket
    );

    if (!isValid) {
      setErrMessage(message);
      setDisable(false);
      return;
    }
  };

  return (
    <div className="ticketform">
      <form onSubmit={submitForm} className="ticketinputFields">
        <div className="ticketinputFlex">
          <input
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Nome Completo"
            disabled={disabled}
            maxLength={32}
            name="displayName"
            className="ticketformfield"
            required=""
            aria-required="true"
          />
        </div>

        <div className="ticketinputFlex">
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
            name="email"
            disabled={disabled}
            className="ticketformfield"
            required=""
            aria-required="true"
          />
        </div>
        <div className="ticketinputFlex" id="ticketsubmit">
          <textarea
            rows="5"
            cols="100"
            onChange={(e) => setSupportTicket(e.target.value)}
            type="text"
            placeholder="Descreva seu problema"
            name="supportticket"
            disabled={disabled}
            className="ticketformfield"
            required=""
            aria-required="true"
          />
        </div>
        <input
          disabled={disabled}
          type="submit"
          value="Enviar"
          className="ticketformbutton"
        />
        <p style={{ color: "white", marginTop: "10px" }}>
          {ErrMessage ? ErrMessage : " "}
        </p>
      </form>
    </div>
  );
};

export default TicketForm;
