import "../App.css";

import dashboardFigma from "../images/dashboardfigma.png";

import Footer from "../components/footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const Home = () => {
  return (
    <div className="pages">
      <motion.div
        className="bgfix"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 1,
            ease: "easeInOut",
          },
        }}
        exit={{ opacity: 0 }}
      >
        <section className="mainpage">
          <div className="mainpagecontainer">
            <div className="mainpageelements">
              <div className="mainpagetext">
                <div className="tittle">
                  <div>Rápido.</div>
                  <div>Prático.</div>
                  <div>Seguro.</div>
                </div>
                <div className="subtittle">
                  <div>Gerencie suas vendas, produtos e faturamento.</div>
                  <div>Tudo em um só lugar.</div>
                </div>
              </div>
              <div className="product-buttons">
                <Link to="/planos">
                  <button className="product-buttons" id="prices">
                    Planos
                  </button>
                </Link>
                <Link to="/diferenciais">
                  <button className="product-buttons" id="know">
                    Saiba mais
                  </button>
                </Link>
              </div>
            </div>
            <img src={dashboardFigma} alt="dashboard" />
          </div>
        </section>
        <section className="secondpage">
          <h1>Uma nova forma de gerenciar o seu negócio.</h1>
          <div className="secondpagecontainer">
            <div className="secondpagetext">
              <div className="secondpage_subtitle">Nossas Ferramentas</div>
              <div className="secondpage_textbuttons">
                <p>
                  <a>Análise de Tendências</a>
                </p>
                <p>
                  <a>Relatórios de faturamento</a>
                </p>
                <p>
                  <a>Lançamento de comandas e vendas</a>
                </p>
                <p>
                  <a>Gerenciamento de produtos e estoque</a>
                </p>
              </div>
            </div>
            <div className="rectangle"></div>
          </div>
        </section>
        <section className="thirdpage">
          <h1>Junte-se a nós hoje mesmo e transforme sua gestão.</h1>
        </section>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Home;
