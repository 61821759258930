import FirebaseManager from "./FirebaseManager";
function formatTime(timestamp) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
      locale: "pt-BR", // Set the locale to Brazilian Portuguese
    };

    const formattedDate = new Intl.DateTimeFormat("pt-BR", options).format(
      timestamp
    );
    return formattedDate;
  }

  async function getSubscriptionTime() {
    const subscriptionTime = await FirebaseManager.getSubscriptionInfo();
    const today = new Date();
    const cycleStart = subscriptionTime.data().current_period_start;
    const cycleEnd = subscriptionTime.data().current_period_end;

    const cycleTimeTotalDays = cycleEnd - cycleStart;
    const cycleTimeSpent = today - cycleEnd;
    const cycleTimeRemaining = cycleEnd - today;

    return {
      cycleStart,
      cycleEnd,
      cycleTimeTotalDays,
      cycleTimeSpent,
      cycleTimeRemaining,
    };
  }


export default {formatTime,getSubscriptionTime}