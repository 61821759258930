import Footer from "../components/footer";
import { motion } from "framer-motion";
import FirebaseManager from "../firebase/FirebaseManager";
import { useNavigate } from "react-router-dom";

const Download = () => {
  const navigate = useNavigate();
  return (
    <div className="pages">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: "easeInOut",
          },
        }}
        exit={{ opacity: 0 }}
      >
        <section className="downloadpage">
          <h1>
            Faça o download e transforme sua gestão para obter melhores
            resultados!
          </h1>
          <div className="downloadpagecontainer">
            <div className="downloadbutton">
              <button>Download Shisha Stream</button>
            </div>
          </div>
        </section>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Download;
