import React, {useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
const Account = ({ userPhoto, user, subscriptionTime,subscriptionTimeEndDate, isMember,userRole }) => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <div className="welcomeuser">
        <img src={userPhoto} alt="perfil" />
        <h2>Bem-vindo, {user.displayName}</h2>
        <p>
          Gerencie suas informações, notificações, segurança e assinatura para
          que o Shisha Stream funcione melhor para você.
        </p>
      </div>
      <div className="userwidgetpage">
      {isMember ?  <button onClick={() => navigate("/minhaassinatura")}  className="userwidget" id="mainwidget">
          <div className="userwidgetcontent">
            <h3>Sua assinatura expira em { subscriptionTime
              
            } dias</h3>
            <p>Sua assinatura termina em {subscriptionTimeEndDate}</p>
          </div>
          <div className="userwidgetbutton">
            <p>Detalhes da assinatura</p>
          </div>
        </button> : <button onClick={() => navigate("/planos")} className="userwidget" id="mainwidget">
          <div className="userwidgetcontent">
            <h3>Você não possui uma assinatura.</h3>
            <p>Não deixe de assinar o Shisha Stream e melhorar a gestão da sua tabacaria</p>
          </div>
          <div className="userwidgetbutton">
            <p>Clique aqui para assinar</p>
          </div>
        </button>}
        <div className="userwidgetscontainer">
          <div className="widgetline">
            <button className="userwidget">
              <div className="userwidgetcontent">
                <h3>Verificação de duas etapas</h3>
                <p>
                  Parece que você ainda não ativou sua verificação de dois
                  fatores.
                </p>
              </div>
              <div className="userwidgetbutton">
                <p> Click me </p>
              </div>
            </button>
            <button className="userwidget">
              <div className="userwidgetcontent">
                <h3>Widget Tittle</h3>
                <p>Widget text goes here</p>
              </div>
              <div className="userwidgetbutton">
                <p> Click me </p>
              </div>
            </button>
          </div>
          <div className="widgetline">
            <button className="userwidget">
              <div className="userwidgetcontent">
                <h3>Widget Tittle</h3>
                <p>Widget text goes here</p>
              </div>
              <div className="userwidgetbutton">
                <p> Click me </p>
              </div>
            </button>
            <button className="userwidget">
              <div className="userwidgetcontent">
                <h3>Widget Tittle</h3>
                <p>Widget text goes here</p>
              </div>
              <div className="userwidgetbutton">
                <p> Click me </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Account;
