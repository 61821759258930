import { motion } from "framer-motion";
import Footer from "../components/footer";
import FirebaseManager from "../firebase/FirebaseManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, Link, useNavigate } from "react-router-dom";
import LoadingPage from "../components/loading";
import UserInfoContext from "../contexts/UserInfoContext";
import { useContext } from "react";

const MinhaAssinatura = () => {
  const [user, loading, error] = useAuthState(FirebaseManager.getAuth());
  const { isMember, userRole, daysLeft, endDate, startDate, userProvider } =
    useContext(UserInfoContext);

  function getRoleFormattedName() {
    switch (userRole) {
      case "shisha_trial":
        return "Shisha Trial";
      case "lounge_essential":
        return "Lounge Essential";
      case "lounge_link":
        return "Lounge Link";
    }
  }

  if (loading) return <LoadingPage />;

  return (
    <div className="pages">
      {user ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.5,
              ease: "easeInOut",
            },
          }}
          exit={{ opacity: 0 }}
        >
          <section className="assinaturapage">
            <div className="assinaturacardcontainer">
              <div className="assinaturacard">
                <div className="assinaturatittle">
                  {isMember
                    ? getRoleFormattedName(userRole)
                    : "currentSubscription"}
                </div>
                <div className="textcontainer">
                  <p>
                    O Shisha Stream está passando por sua{" "}
                    <b>fase final de testes.</b>
                    <br />
                    Por conta disso, essa assinatura está disponível para você
                    usar a plataforma com um investimento pequeno.
                  </p>
                </div>
                <ul>
                  <li>
                    <b>Disponível até o fim da fase de testes.</b>
                  </li>
                  <li>
                    50% de desconto na sua assinatura no
                    <br />
                    1º mês <b>após a fase de testes</b>.
                  </li>
                  <li>Até 8 funcionários.</li>
                  <li>Até 3 pontos de venda.</li>
                  <li>Relatórios de performance em tempo real.</li>
                </ul>
              </div>
              <Link to="/planos">
                <button className="assinaturabuttons">
                  <p>Melhorar meu plano</p>
                </button>
              </Link>
              <Link to="/minhaconta/assinatura">
                <button className="assinaturabuttons">
                  <p>Opções de Pagamento</p>
                </button>
              </Link>
            </div>
            <div className="assinaturadetails">
              <h2>Detalhes do plano atual</h2>
              <ul>
                <li>
                  <h4>Sua assinatura expira em {daysLeft} dias.</h4>
                </li>
                <li>Início do ciclo: {startDate}</li>
                <li>Fim do ciclo: {endDate}</li>
              </ul>
            </div>
          </section>
        </motion.div>
      ) : (
        <Navigate to="/login" />
      )}
      <Footer />
    </div>
  );
};

export default MinhaAssinatura;
