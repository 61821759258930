import "./LogInPage.css";

import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import firebaseManager from "./FirebaseManager";
import FormValidation from "./FormValidation";
import ErrorPopup from "../components/errorpopup";
import PopupManager from "../components/PopupManager";
import { v4 as uuidv4 } from "uuid";

const LogInPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ErrMessage, setErrMessage] = useState("");
  const [isVisibleError, setErrorVisible] = useState(false);
  const [disabled, setDisable] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [errors, setErrors] = useState([]);

  const addError = (message, type) => {
    const newError = { id: uuidv4(), message, type };
    setErrors([...errors, newError]);
  };

  const removeError = (id) => {
    const updatedErrors = errors.filter((error) => error.id !== id);
    setErrors(updatedErrors);
  };

  const redirectAfterPath = searchParams.get("redirectAfter");

  const sendResetPasswordEmail = async (email) => {
    setDisable(true);

    try {
      await firebaseManager.sendResetPasswordEmail(email);
      setDisable(false);
    } catch (error) {
      console.log(error);
      addError(firebaseManager.handleError(error), "error");
      setDisable(false);
    }
  };

  const submitForm = async (event) => {
    setDisable(true);
    event.preventDefault();
    const { isValid, message } = FormValidation.validateLoginInputs(
      email,
      password
    );

    if (!isValid) {
      console.log(message);
      for (let errMsg in message) {
        console.log(errMsg);
        addError(message[errMsg], "error");
      }

      setDisable(false);
      return;
    }

    try {
      await firebaseManager.signInWithEmail(email, password);
      navigate("/");
    } catch (error) {
      console.log(error);
      addError(firebaseManager.handleError(error), "error");
      setDisable(false);
    }
  };

  return (
    <div className="loginPlaceholder">
      <PopupManager errors={errors} removeFunction={removeError} />
      <div className="form-container">
        <p className="title">Faça seu Login</p>
        <form onSubmit={submitForm} className="form">
          <div className="input-group">
            <label for="username">
              Email<mark style={{ background: "none", color: "gold" }}> *</mark>
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              disabled={disabled}
              required=""
              aria-required="true"
              id="username"
              placeholder=""
            />
          </div>
          <div className="input-group">
            <label for="password">
              Senha
              <mark
                style={{
                  background: "none",
                  color: "gold",
                }}
              >
                {" "}
                *
              </mark>
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              maxLength={32}
              disabled={disabled}
              name="password"
              classNameName="loginemail"
              required=""
              aria-required="true"
              id="password"
              placeholder=""
            />
            <div className="forgot">
              <a
                onClick={async () => {
                  if (disabled) return;
                  try {
                    if (!FormValidation.validateEmail(email)) {
                      setErrMessage(
                        firebaseManager.handleError({
                          code: "shisha-api/email-malformatted",
                        })
                      );
                      return;
                    }

                    await firebaseManager.requestPasswordChangeLink(email);
                    setErrMessage(
                      "Pedido de redefinir senha enviado com sucesso"
                    );
                  } catch (error) {
                    console.log(error);
                    setErrMessage(firebaseManager.handleError(error));
                  }
                }}
              >
                Esqueceu sua senha?
              </a>
            </div>
          </div>
          <div class="container">
            <input type="checkbox" id="cbx2" style={{ display: "none" }} />
            <label for="cbx2" className="check">
              <svg width="18px" height="18px" viewBox="0 0 18 18">
                <path d="M 1 9 L 1 9 c 0 -5 3 -8 8 -8 L 9 1 C 14 1 17 5 17 9 L 17 9 c 0 4 -4 8 -8 8 L 9 17 C 5 17 1 14 1 9 L 1 9 Z"></path>
                <polyline points="1 9 7 14 15 4" />
              </svg>
            </label>
          </div>
          <button className="sign">Entrar</button>
        </form>
        <div className="social-message">
          <div className="line" />
          <p className="message">Outras opções</p>
          <div className="line" />
        </div>
        <div className="social-icons">
          <button
            onClick={async () => {
              try {
                await firebaseManager.signInGoogle();
                navigate("/");
              } catch (error) {
                setErrMessage(firebaseManager.handleError(error.code));
              }
            }}
            aria-label="Log in with Google"
            className="icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              className="w-5 h-5 fill-current"
            >
              <path d="M16.318 13.714v5.484h9.078c-0.37 2.354-2.745 6.901-9.078 6.901-5.458 0-9.917-4.521-9.917-10.099s4.458-10.099 9.917-10.099c3.109 0 5.193 1.318 6.38 2.464l4.339-4.182c-2.786-2.599-6.396-4.182-10.719-4.182-8.844 0-16 7.151-16 16s7.156 16 16 16c9.234 0 15.365-6.49 15.365-15.635 0-1.052-0.115-1.854-0.255-2.651z"></path>
            </svg>
          </button>
        </div>
        <p className="signup">
          Não tem uma conta? {""}
          <Link to="/register">Cadastre-se</Link>
        </p>
      </div>
    </div>
  );
};

export default LogInPage;
