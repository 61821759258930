import Footer from "../components/footer";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

function ErrorPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const error = searchParams.get("error");
  return (
    <div className="pages">
      <section className="errorpage">
        <div className="warning">
          <div>
            <h1>Opa, parece que rolou um erro por aqui...</h1>
            <p style={{ textAlign: "center" }}>{error ? error : ""}</p>
          </div>
          <div>
            <Link className="errorbutton" to="/home">
              <button>Clique aqui para voltar para a página inicial</button>
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ErrorPage;
