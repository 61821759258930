import { Link } from "react-router-dom";

const FaleConoscoWidget = () => {
  return (
    <div className="pagesuserwidget">
      <div className="pagesuserwidgetcontent" id="accounthelp">
        <h3>Não era isso que você estava procurando?</h3>
        <Link to={"/ajuda"}>
          <button className="pagesuserbutton">
            <div className="pagesuserbuttonwrapper">
              <div className="pagesusermain">
                <div className="pagesuserhelpbutton">
                  <i class="bx bx-chat"></i>
                </div>
                <p className="pagesuserbuttontext">Fale Conosco</p>
              </div>
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default FaleConoscoWidget;
