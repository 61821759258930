import { loadStripe } from '@stripe/stripe-js'

let stripePromise;

const initializeStripe = async () => {

    if (!stripePromise) stripePromise = await loadStripe("pk_test_51NOlbZJvn0359VYGQkJzdaJjFQgTj11QtBNzCuzVNNNLV7l29lWdLWltuUiVDgQjzlA5SK9oqIAuSTopbJchXXxW00wAbYI4xW")
    return stripePromise;
}


export default initializeStripe;