import React, { useState } from 'react';

const InlineEditInput = ({ text, onTextChanged }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(text);
  
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        setIsEditing(false);
        onTextChanged(inputValue);
      }
    };
  
    return (
      <div onClick={() => setIsEditing(true)}>
        {isEditing ? (
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <span>{text}</span>
        )}
      </div>
    );
  };
  
  export default InlineEditInput;