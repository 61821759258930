import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import AccountNavList from "../../components/accountnavlist";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import FirebaseManager from "../../firebase/FirebaseManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from "react-router-dom";
import LoadingPage from "../../components/loading";
import FaleConoscoWidget from "../../components/FaleConoscoWidget";
import InlineEditInput from "../../components/inlineinput";


const InformacoesPessoais = () => {
  const [user, loading, error] = useAuthState(FirebaseManager.getAuth());
  const [inputText, setInputText] = useState(user.email);

  if (loading) return <LoadingPage />;
  if (error) return <Navigate to="error" />;
  if (!user) return <Navigate to="/" />;

  async function changeEmail() {
    // Change Email Logic
  }

  const handleTextChanged = (newText) => {
    setInputText(newText);
  };

  return (
    <React.Fragment>
      <div className="pagesuserheader">
        <h2>Informações pessoais</h2>
        <p>
          Informações sobre você e suas preferências nos Serviços do Shisha
          Stream
        </p>
      </div>
      <div className="pagesuserwidgetscontainer">
        <div className="pagesuserwidget">
          <div className="pagesuserwidgetcontent">
            <h3>Informações Básicas</h3>
            <button className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper">
                <div className="pagesusermain">
                  <p className="pagesuserbuttonlabel">Nome</p>
                  <p className="pagesuserbuttontext">{user.displayName}</p>
                </div>
                <div className="pagesuserbuttonicon">
                  <i
                    className="bx bx-chevron-right bx-flip-vertical"
                    style={{ color: "hsla(0, 0%, 57%, 1)" }}
                  ></i>
                </div>
              </div>
            </button>
            <button className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper">
                <div className="pagesusermain">
                  <p className="pagesuserbuttonlabel">Gênero</p>
                  <p className="pagesuserbuttontext">A definir</p>
                </div>
                <div className="pagesuserbuttonicon">
                  <i
                    className="bx bx-chevron-right bx-flip-vertical"
                    style={{ color: "hsla(0, 0%, 57%, 1)" }}
                  ></i>
                </div>
              </div>
            </button>
            <button className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper">
                <div className="pagesusermain">
                  <p className="pagesuserbuttonlabel">Data de nascimento</p>
                  <p className="pagesuserbuttontext">A definir</p>
                </div>
                <div className="pagesuserbuttonicon">
                  <i
                    className="bx bx-chevron-right bx-flip-vertical"
                    style={{ color: "hsla(0, 0%, 57%, 1)" }}
                  ></i>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className="pagesuserwidget">
          <div className="pagesuserwidgetcontent">
            <h3>Informações de Contato</h3>

            <button className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper">
                <div className="pagesusermain">
                  <p className="pagesuserbuttonlabel">Email</p>
                  <InlineEditInput className="pagesuserbuttontext" text={inputText} onTextChanged={handleTextChanged} />
                </div>
                <div className="pagesuserbuttonicon">
                  <i
                    className="bx bx-chevron-right bx-flip-vertical"
                    style={{ color: "hsla(0, 0%, 57%, 1)" }}
                  ></i>
                </div>
              </div>
            </button>
            <button className="pagesuserbutton">
              <div className="pagesuserbuttonwrapper">
                <div className="pagesusermain">
                  <p className="pagesuserbuttonlabel">Telefone</p>
                  <p className="pagesuserbuttontext">A definir</p>
                </div>
                <div className="pagesuserbuttonicon">
                  <i
                    className="bx bx-chevron-right bx-flip-vertical"
                    style={{ color: "hsla(0, 0%, 57%, 1)" }}
                  ></i>
                </div>
              </div>
            </button>
          </div>
        </div>
        <FaleConoscoWidget />
      </div>
    </React.Fragment>
  );
};

export default InformacoesPessoais;
